var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "app-form",
    {
      attrs: {
        id: "appointments-payment-form",
        submitAction: _vm.update,
        submitSuccessAction: _vm.updateSuccess,
        validationOptions: _vm.validationOptions
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("app-datepicker", {
            attrs: {
              id: "PaymentDate",
              placeholder: _vm.$t("payments.paymentDate"),
              cssClass: "e-field"
            },
            model: {
              value: _vm.paymentDate,
              callback: function($$v) {
                _vm.paymentDate = $$v
              },
              expression: "paymentDate"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("app-textbox", {
            attrs: {
              id: "PaymentNotes",
              placeholder: _vm.$t("payments.paymentNote")
            },
            model: {
              value: _vm.paymentNotes,
              callback: function($$v) {
                _vm.paymentNotes = $$v
              },
              expression: "paymentNotes"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("h3", { staticStyle: { "padding-top": "16px" } }, [
            _vm._v(_vm._s(_vm.$t("payments.selectedAppointments")))
          ]),
          _c(
            "ejs-grid",
            { attrs: { dataSource: _vm.appointments } },
            [
              _c(
                "e-columns",
                [
                  _c("e-column", {
                    attrs: {
                      field: "CustomerFullName",
                      headerText: _vm.$t("customers.customer"),
                      width: "160"
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "StartDateTime",
                      headerText: _vm.$t("calendar.startTime"),
                      width: "80",
                      type: "dateTime",
                      format: { type: "dateTime", skeleton: "short" }
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "EndDateTime",
                      headerText: _vm.$t("calendar.endTime"),
                      width: "80",
                      type: "dateTime",
                      format: { type: "dateTime", skeleton: "short" }
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "OfficeName",
                      headerText: _vm.$t("offices.office"),
                      width: "100",
                      visible: _vm.hasMultipleOffices
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "ServiceName",
                      headerText: _vm.$t("services.service"),
                      width: "120"
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "EmployeeFullName",
                      headerText: _vm.$t("employees.employee"),
                      width: "160"
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "IsTrial",
                      headerText: _vm.$t("calendar.trial"),
                      width: "80",
                      valueAccessor: _vm.yesNoGridValueMapper,
                      filter: _vm.yesNoDropDownFilter
                    }
                  }),
                  _c("e-column", {
                    attrs: {
                      field: "Cost",
                      headerText: _vm.$t("shared.cost"),
                      width: "80",
                      type: "number",
                      format: "C2"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("app-form-buttons", {
        attrs: {
          addSubmitButton: true,
          submitButtonText: _vm.$t("payments.update.submit"),
          addCancelButton: true,
          cancelAction: _vm.cancel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }