var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("ejs-dialog", {
        ref: "appointmentsPaymentDialog",
        attrs: {
          id: "appointments-payment-dialog",
          isModal: true,
          header: _vm.$t("payments.status"),
          width: "50%",
          animationSettings: _vm.animationSettings,
          content: _vm.contentTemplate,
          visible: false,
          showCloseIcon: true,
          close: _vm.onClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }